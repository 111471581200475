<template>
  <div>
    <div class="floating-panel">
      <div class="form-group form-group-map">
        <div class="input-group">
          <input
            type="text"
            v-model="query_string"
            class="form-control"
            v-bind:placeholder="$t('search')"
            ref="query_string"
          />
          <div class="input-group-addon btn" v-on:click="resetQuery">
            <i v-if="query_string" class="fa fa-close"></i>
            <i v-else class="fa fa-search"></i>
          </div>
        </div>
      </div>

      <div class="form-group form-group-map">
        <div class="">
          <label class="filter-option">
            <input
              type="radio"
              v-model="query_connection"
              name="query_connection"
              value="all"
            />
            <span>{{ $tc("all") }}</span>
          </label>
          <label class="filter-option">
            <input
              type="radio"
              v-model="query_connection"
              name="query_connection"
              value="connected"
            />
            <span>{{ $tc("connected") }}</span>
          </label>
          <label class="filter-option">
            <input
              type="radio"
              v-model="query_connection"
              name="query_connection"
              value="disconnected"
            />
            <span>{{ $tc("disconnected") }}</span>
          </label>
        </div>
        <div class="" style="position: relative">
          <label class="filter-option">
            <input type="checkbox" v-model="query_alarm" name="query_alarm" />
            <span>{{ $t("alarm_active") }}</span>
          </label>
          <span
            class="clicable"
            style="position: absolute; right: 10px; font-size: 1.1em"
            @click.stop.prevent="togglePOI"
          >
            <i
              class="fa fa-info-circle"
              :class="poi ? ' text-primary' : ''"
            ></i>
          </span>
        </div>
      </div>
    </div>
    <div ref="map" style="height: 84.4vh"></div>
  </div>
</template>

<script>
import GoogleMaps from "@/plugins/gmaps";

export default {
  name: "EquipmentMapPanel",
  props: {
    equipmentList: {
      type: Array,
      required: false,
      default: () => []
    },
    display: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      show: "all",
      poi: false,
      query_string: "",
      query_connection: "all",
      query_alarm: false,
      isReady: false,
      raio: 1500,
      center: null,
      markers: [],
      currentMaker: null,
      icons: {
        blue: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
        green: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
        ltblue: "http://maps.google.com/mapfiles/ms/icons/ltblue-dot.png",
        orange: "http://maps.google.com/mapfiles/ms/icons/orange-dot.png",
        pink: "http://maps.google.com/mapfiles/ms/icons/pink-dot.png",
        purple: "http://maps.google.com/mapfiles/ms/icons/purple-dot.png",
        red: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
        question: "http://maps.google.com/mapfiles/ms/icons/question.png",
        yellow: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
        info: "http://maps.google.com/mapfiles/ms/icons/info.png",
        info_cicle: "http://maps.google.com/mapfiles/ms/micons/info_circle.png",
        caution: "http://maps.google.com/mapfiles/ms/micons/caution.png"
      }
    };
  },
  watch: {
    equipmentList: {
      handler(n, o) {
        if (n && o && n.length && o.length) {
          this.updateMarkers();
        }
      },
      deep: true
    },
    markerList(newValue) {
      var self = this;
      self.markers.forEach((marker) => {
        marker.setMap(null);
      });
      newValue.forEach((marker) => {
        marker.setMap(self.map);
      });
    }
  },
  computed: {
    marker_header() {
      return this?.display?.options?.marker_header || "";
    },
    marker_connection() {
      return this?.display?.options?.marker_connection || "";
    },
    marker_customer() {
      return this?.display?.options?.marker_customer || "";
    },
    marker_location() {
      return this?.display?.options?.marker_location || "";
    },
    markerList() {
      if (!this?.equipmentList?.length) return [];
      var self = this;
      return self.markers.filter((item) => {
        var equipment = this.getEquipmentById(item.data);
        return (
          self.$utils.queryStrAtr(self.query_string, equipment) &&
          // validate connection state
          (self.query_connection == "all" ||
            (self.query_connection == "connected" && equipment.is_connected) ||
            (self.query_connection == "disconnected" &&
              !equipment.is_connected)) &&
          // validate alarm state
          (!self.query_alarm ||
            (self.query_alarm && equipment.has_active_alarms))
        );
      });
    }
  },
  methods: {
    getEquipmentById(connId) {
      return this.equipmentList.find(({ id }) => id == connId);
    },
    updateMarkers() {
      let self = this;
      self.markers.forEach((marker) => {
        var equipment = this.getEquipmentById(marker.data);
        var icon = [self.icons.red, self.icons.green, self.icons.yellow][
          equipment.has_active_alarms ? 0 : equipment.is_connected ? 1 : 2
        ];
        if (icon != marker.getIcon()) {
          marker.setIcon(icon);
          if (self.currentMaker && self.currentMaker.anchor == marker) {
            self.currentMaker.setContent(self.getMarkerContent(marker));
          }
        }
      });
    },
    resetQuery() {
      this.query_string = "";
    },
    onLocationError(browserHasGeolocation) {
      var infoWindow = new this.google.maps.InfoWindow();
      infoWindow.setPosition(map.getCenter());
      infoWindow.setContent(
        browserHasGeolocation
          ? "<span>O serviço de Geolocalização falhou!</span><br>"
          : "<span>Seu navegador não suporta Geolocalização.</span>"
      );
      infoWindow.open(map);
    },
    loadMarkers() {
      var self = this;
      self.equipmentList.forEach((equipment) => {
        self.addMarker(equipment);
      });
      self.isReady = true;
    },
    isLocationFree(latLng) {
      var self = this;
      var lst = self.markers.filter((item) => {
        return (
          item.position.lat() === latLng[0] && item.positionlng() === latLng[1]
        );
      });
      return !lst.length;
    },
    addMarker(equipment) {
      /*
      equipment.location_address
        {city: "Indaiatuba", state: "SP", latlng: "-23.0517395,-47.21582679999999", number: "", region: "SUDESTE", …}
        city: "Indaiatuba"
        state: "SP"
        latlng: "-23.0517395,-47.21582679999999"
        number: ""
        region: "SUDESTE"
        street: "Rua Serra do Roncador"
        country: "Brasil"
        zipcode: "13332"
        district: "Jardim Reserva Bom Viver de Indaiatuba"
        extrainfo: ""
      */
      var self = this;
      if (equipment.location_address && equipment.location_address.latlng) {
        var latLng = equipment.location_address.latlng.split(",");
        if (self.isLocationFree(latLng)) {
          var icon = [self.icons.red, self.icons.green, self.icons.yellow][
            equipment.has_active_alarms ? 0 : equipment.is_connected ? 1 : 2
          ];
          var title = (
            equipment.name +
            " " +
            (equipment.serial_number || "")
          ).toUpperCase();
          if (equipment.has_active_alarms) {
            title += "\n" + self.$t("alarmed");
          }
          if (equipment.is_connected) {
            title += "\n" + self.$tc("connected");
          } else {
            title += "\n" + self.$tc("disconnected");
          }
          //========================================================
          var position = new self.google.maps.LatLng(latLng[0], latLng[1]);
          var marker = new self.google.maps.Marker({ position: position });
          self.markers.push(marker);
          marker.data = equipment.id;
          marker.setAnimation(self.google.maps.Animation.DROP);
          marker.setTitle(title);
          marker.setIcon(icon);
          marker.setMap(self.map);
          self.listenMarker(marker);
        }
      }
    },
    parseCustomHTML(connector, custom, default_tpl) {
      return (
        this.$utils.evaluate(
          {
            connector: connector || {}
          },
          custom
        ) ||
        default_tpl ||
        ""
      );
    },
    getMarkerContent(equipment_id) {
      let equipment = this.getEquipmentById(equipment_id);
      if (!equipment) return "";
      let name = equipment?.name || "";
      let serialnumber = equipment?.serial_number || "";
      let customer = equipment?.location_name || "";
      let address =
        (equipment?.location_address?.state || "") +
        " / " +
        (equipment?.location_address?.city || "");
      let connection = this.$tc(
        equipment?.is_connected ? "connected" : "disconnected",
        1
      );
      let alarm = equipment?.has_active_alarms
        ? this.$tc("has_active_alarms", 1).replace(/\?/, "")
        : this.$tc("normal");
      if (equipment?.has_active_alarms) {
        alarm = `<i class="fa fa-bell"></i> ${alarm}`;
      }
      let alarm_style = equipment?.has_active_alarms
        ? "color:#b65a3c;font-family: FontAwesome;"
        : "color:transparent;font-family: FontAwesome;";
      let connection_color = equipment?.is_connected ? "green" : "gray";
      // let equipment_id = equipment.id || "";
      let connector_name = this.$tc("connector");
      let marker_header = this.parseCustomHTML(
        equipment,
        this.marker_header,
        ` <div>` +
          `  <h5 style="text-align:center;white-space:nowrap;"><b>${connector_name}: ${name}</b></h5>` +
          `  <div style="text-align:center;white-space:nowrap;">s/n ${serialnumber}</div>` +
          `</div>`
      );
      let marker_connection = this.parseCustomHTML(
        equipment,
        this.marker_connection,
        `
        <div style="text-align:center;margin:15px 0; font-family: Arial;">
         <div style="font-size:16pt; color: ${connection_color}">
         ${connection}
         </div>
         <div style="${alarm_style};">
         ${alarm}
         </div>
        </div>
        `
      );
      let marker_customer = this.parseCustomHTML(
        equipment,
        this.marker_customer,
        `<div style="text-align:center;font-size:10pt; font-family: arial;margin:20px 0;">${customer}</div>`
      );
      let marker_location = this.parseCustomHTML(
        equipment,
        this.marker_location,
        `<div style="text-align:center;font-size:12pt; font-weight:bold;font-family: arial;margin:10px 0 20px 0;">${address}</div>`
      );
      let html =
        `<div class="placeBaloon" style="text-align: center;background-color: #fff;">` +
        ` <div>` +
        ` ${marker_header}` +
        ` ${marker_connection}` +
        ` ${marker_customer}` +
        ` ${marker_location}` +
        ` </div>` +
        ` <form class="bs-component" id="form_search" data-target="">` +
        `	  <div><span class="help-block text-center"></span></div>` +
        `   <div class="text-center" style="margin-top:5px">` +
        `	    <button class="btn btn-primary btn-sm btn-show" value="${equipment_id}" style="width:100%"><i class="fa fa-check"></i> Detalhes</button>` +
        `   </div>` +
        ` </form>` +
        `</div>`;
      return html;
    },
    showMarkerPopup(marker) {
      // let equipment_id = marker && marker.data;
      let content =
        (marker && marker.data && this.getMarkerContent(marker.data)) || null;
      if (!content) return;

      var info = new this.google.maps.InfoWindow({
        content: content,
        maxWidth: 400,
        minWidth: 300
      });
      info.open(this.map, marker);
      if (this.currentMaker && this.currentMaker != marker) {
        this.currentMaker.close();
      }
      this.currentMaker = info;
      this.google.maps.event.addListener(info, "domready", () => {
        $("div.placeBaloon")
          .parent()
          .css("overflow", "unset")
          .parent()
          .css("min-height", "170px");
        $("button.btn-show").click((e) => {
          e.stopPropagation();
          e.preventDefault();
          this.$router.push(`/dashboard/equipment/${e.target.value}`);
          return false;
        });
      });
    },
    listenMarker(marker) {
      var self = this;
      // https://developers.google.com/maps/documentation/javascript/tutorial?hl=pt-br
      marker.addListener("click", function () {
        self.showMarkerPopup(this);
      });
    },
    save() {
      var self = this;
      window.localStorage.setItem(
        "map",
        JSON.stringify({
          center: {
            lat: self.map.getCenter().lat(),
            lng: self.map.getCenter().lng()
          },
          zoom: self.map.getZoom()
        })
      );
    },
    restore() {
      var data = window.localStorage.getItem("map");
      return (data && JSON.parse(data)) || null;
    },
    togglePOI() {
      this.poi = !this.poi;
      this.map.setOptions({ styles: this.getPOIStyle() });
    },
    getPOIStyle() {
      return [
        {
          featureType: "poi",
          stylers: [{ visibility: this.poi ? "on" : "off" }]
        },
        {
          featureType: "transit",
          elementType: "labels.icon",
          stylers: [{ visibility: this.poi ? "on" : "off" }]
        }
      ];
    },
    buildMap() {
      var self = this;
      var saved = self.restore();
      var custom = {
        center: [
          (saved && saved.center.lat) || -14.5384707,
          (saved && saved.center.lng) || -53.3690407
        ],
        bounds: {
          north: 13.483928,
          south: -33.143587,
          west: -76.88277,
          east: -31.986905
        },
        zoom: (saved && saved.zoom) || 4.7,
        minZoom: 4.7
      };
      self.map = new self.google.maps.Map(self.$refs.map, {
        mapTypeId: self.google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false,
        scrollwheel: true,
        center: new self.google.maps.LatLng(custom.center[0], custom.center[1]),
        zoom: custom.zoom || 4.7,
        minZoom: custom.minZoom || 4.7,
        streetViewControl: false,
        restriction: {
          latLngBounds: custom.bounds,
          strictBounds: false
        },
        styles: this.getPOIStyle()
      });
      self.map.addListener("center_changed", () => {
        setTimeout(self.save, 10, self);
      });
    }
  },
  mounted() {
    let self = this;
    (async () => {
      self.google = await GoogleMaps(
        self.$store.getters["dashboard/property"]("gmaps_api_key")
      );
      self.buildMap();
      self.loadMarkers();
    })();
  },
  created() {
    this.map = null;
    this.google = null;
  }
};
</script>

<style scoped>
.floating-panel {
  position: absolute;
  max-width: 300px;
  top: 10px;
  left: 10px;
  z-index: 5;
  background-color: #0000000f;
  border-radius: 5px;
  padding: 6px;
  text-align: center;
  font-family: "Roboto", "sans-serif";
  line-height: 30px;
  padding-left: 10px;
}
.form-group-map {
  margin-bottom: 0 !important;
  text-align: left;
}

.filter-option {
  margin-right: 10px;
}
.filter-option span {
  vertical-align: top;
  margin-left: 3px;
}

.filter-option:hover {
  cursor: pointer;
  color: #673ab7;
}
.clicable {
  cursor: pointer;
  opacity: 0.8;
}
</style>
